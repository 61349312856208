import React, { useEffect, useState } from 'react';
import useOfflineStatus from '../../../hooks/useOfflineStatus.js';
import * as Sentry from '@sentry/react';
import { updateInventoryQuantities } from '../../../db/utils.js';
import { useLocalStorage } from 'react-use';
import { API_ROOT } from '../../../helpers/constants.js';
import Notification from '../Notification.js';
import { Text } from 'mpharma-i18n';
import { get } from '../../../admin/api/index.js';

const QuantityNotification = ({ user }) => {
  const { online } = useOfflineStatus();
  const [, setLastQuantitiesUpdate] = useLocalStorage('bloom:lastQuantitiesUpdate');
  const [, setNumberOfAttemptCallToGetQuantity] = useLocalStorage('numberOfAttemptCallToGetQuantity', 0);
  const [openNewQuantityNotification, setOpenNewQuantityNotification] = useState(false);

  useEffect(() => {
    if (online && user?.facility_id) {
      getQuantityUpdates(user?.facility_id);
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [online]);

  const getLastFetchedDate = () => {
    try {
      const data = localStorage.getItem('bloom:lastQuantitiesUpdate');
      return JSON.parse(data);
    } catch (error) {
      Sentry.captureMessage(error?.message);
      return undefined;
    }
  };

  async function fetchNewQuantityRecords(responseData, baseURL) {
    responseData.results.forEach(result => {
      updateInventoryQuantities(result.uuid, result);
    });

    if (!responseData.next) {
      const timestamp = new Date().toISOString();
      setLastQuantitiesUpdate(timestamp);
      setOpenNewQuantityNotification(true);
      return;
    }

    const nextUrl = new URL(responseData.next);
    const record = await get(`${baseURL}&page=${nextUrl.searchParams.get('page')}`, {
      withCredentials: true
    });

    return fetchNewQuantityRecords(record);
  }

  async function getQuantityUpdates(facilityId) {
    let lastFetchDate = getLastFetchedDate();
    if (lastFetchDate) {
      const BASE_URL = `${API_ROOT}/inventory-bff/productaliases/?facility_id=${facilityId}&since=${lastFetchDate}`;
      try {
        const data = await get(BASE_URL, { withCredentials: true });

        if (data && data.count <= 0) {
          const timestamp = new Date().toISOString();
          setLastQuantitiesUpdate(timestamp);
          return;
        }

        await fetchNewQuantityRecords(data, BASE_URL);
      } catch (error) {
        let attempted = parseInt(localStorage.getItem('numberOfAttemptCallToGetQuantity'));
        Sentry.captureException(error, {
          tags: {
            facilityId: facilityId,
            lastFetchDate: lastFetchDate,
            attempted: attempted
          }
        });
        if (attempted <= 3) {
          getQuantityUpdates(user.facility_id);
          setNumberOfAttemptCallToGetQuantity(attempted + 1);
        } else {
          setNumberOfAttemptCallToGetQuantity(0);
        }
      }
    } else {
      const timestamp = new Date().toISOString();
      setLastQuantitiesUpdate(timestamp);
    }
  }
  return (
    <>
      {openNewQuantityNotification && (
        <Notification
          action={<Text i18nKey="notifications.newPrice.action">View the list</Text>}
          link="/inventory/all"
          message={
            <Text i18nKey="notifications.newQuantity.message">
              You have a number of products that have new quantities
            </Text>
          }
          info
          handleClose={() => setOpenNewQuantityNotification(false)}
        />
      )}
    </>
  );
};

export default QuantityNotification;
