import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { updateInventory } from '../../../db/utils.js';
import useDownloadProgress from '../../../hooks/useDownloadProgress.js';
import * as Sentry from '@sentry/react';
import { useLocalStorage } from 'react-use';
import { Text } from 'mpharma-i18n';
import useOfflineStatus from '../../../hooks/useOfflineStatus.js';
import {
  API_ROOT,
  CURRENT_PRICE_UPLOAD_COUNTRY,
  CURRENT_PRICE_UPLOAD_FACILITY,
  PRICE_UPLOAD_PROCESSING_STATUS
} from '../../../helpers/constants.js';
import { get } from '../../../admin/api/index.js';
import Notification from '../Notification.js';
import { ReactComponent as PriceUploadErrorAlertIcon } from '../../../images/PriceUploadErrorAlertIcon.svg';
import usePriceUploadProgressBroadcastChannel from '../../../hooks/usePriceUploadProgressBroadcastChannel';

const PricingNotification = ({
  user,
  resourceDownloadWorker,
  setOpenPriceUpdateModal,
  setOpenedNotificationDrawer
}) => {
  const { online } = useOfflineStatus();
  const history = useHistory();
  const { priceUpdateProgress, setPriceUpdateProgress } = useDownloadProgress(resourceDownloadWorker);
  const [downloadingNewPrice, setDownloadingNewPrice] = useState(false);
  const [openNewPriceNotification, setOpenNewPriceNotification] = useState(false);
  const [openPriceUploadErrorNotification, setOpenPriceUploadErrorNotification] = useState(true);
  const [lastInventoryUpdate, setLastInventoryUpdate] = useLocalStorage('bloom:lastInventoryUpdate');
  const [pricesUpdated, setPricesUpdated] = useLocalStorage('bloom:pricesUpdated');
  const [, setPriceUpdateLocal] = useLocalStorage('bloom:priceUpdateProgress');

  const {
    priceErrorCount,
    priceUploadProcessingStatus,
    priceUploadCountry,
    uploadProgressChannel,
    totalUploadRecords,
    priceUploadFacilityId,
    isBulkPriceUpload,
    priceUploadErrorMessage
  } = usePriceUploadProgressBroadcastChannel();

  useEffect(() => {
    if (online && !pricesUpdated && user && user.facility_id && !user.is_staff) {
      if (lastInventoryUpdate) {
        getPriceUpdates(user.facility_id, lastInventoryUpdate);
      } else {
        const timestamp = new Date().toISOString();
        setLastInventoryUpdate(timestamp);
      }
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [online, user?.facility_id]);

  useEffect(() => {
    setPriceUpdateLocal(priceUpdateProgress.progress);
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [priceUpdateProgress.progress]);

  const cachedCountry =
    localStorage.getItem(CURRENT_PRICE_UPLOAD_COUNTRY) &&
    localStorage.getItem(CURRENT_PRICE_UPLOAD_COUNTRY) !== 'undefined'
      ? localStorage.getItem(CURRENT_PRICE_UPLOAD_COUNTRY)
      : '';

  function handleGoToUpload(history) {
    if (isBulkPriceUpload || priceUploadCountry || cachedCountry) {
      history.push(`/inventory/price-list/${priceUploadCountry || cachedCountry}`);
      setOpenedNotificationDrawer(false);
    } else if (priceUploadFacilityId || localStorage.getItem(CURRENT_PRICE_UPLOAD_FACILITY)) {
      history.push(
        `/inventory/facilities/${priceUploadFacilityId || localStorage.getItem(CURRENT_PRICE_UPLOAD_FACILITY)}/profile`
      );
      setOpenedNotificationDrawer(false);
    }

    uploadProgressChannel.postMessage({
      shouldDisplayUploadProgress: true,
      isBulkPriceUpload
    });
  }

  async function getPriceUpdates(facilityId, lastInventoryUpdateParam) {
    const BASE_URL = `${API_ROOT}/inventory-bff/productaliases/new-prices/?facility_id=${facilityId}&created_after=${lastInventoryUpdateParam}`;
    try {
      const data = await get(BASE_URL, { withCredentials: true });
      if (data?.count > 0) {
        setDownloadingNewPrice(true);
      } else {
        return;
      }

      async function fetchRecords(responseData) {
        responseData.results.forEach(result => {
          updateInventory(result.uuid, result);
        });

        if (!responseData.next) {
          const timestamp = new Date().toISOString();
          setLastInventoryUpdate(timestamp);
          setPricesUpdated(true);
          setDownloadingNewPrice(false);
          setOpenNewPriceNotification(true);
          return;
        }

        const nextUrl = new URL(responseData.next);
        let page = nextUrl.searchParams.get('page');
        //calculate the progress percentage.
        let priceDownloadPercentage = ((page * 25) / data.count) * 100;
        setPriceUpdateProgress({ ...priceUpdateProgress, progress: Math.trunc(priceDownloadPercentage) });

        const record = await get(`${BASE_URL}&page=${nextUrl.searchParams.get('page')}`, {
          withCredentials: true
        });

        return fetchRecords(record);
      }

      await fetchRecords(data);
    } catch (error) {
      Sentry.captureException(error, {
        tags: {
          facilityId: facilityId,
          lastFetchDate: lastInventoryUpdateParam
        }
      });
      setOpenPriceUpdateModal(false);
      // Retry after 10 seconds
      setTimeout(() => {
        if (!pricesUpdated) {
          getPriceUpdates(user.facility_id, lastInventoryUpdateParam);
        }
      }, 10000);
    }
  }
  return (
    <>
      {openNewPriceNotification && (
        <Notification
          action={<Text i18nKey="notifications.newPrice.action">View the list</Text>}
          link="/inventory/new-prices"
          message={
            <Text i18nKey="notifications.newPrice.message">You have a number of products that have new prices</Text>
          }
          info
          handleClose={() => setOpenNewPriceNotification(false)}
        />
      )}

      {downloadingNewPrice && (
        <Notification
          message={<Text i18nKey="home.downloadingNewPrices">Downloading new prices</Text>}
          info
          handleClose={() => setDownloadingNewPrice(false)}
        />
      )}

      {priceUploadProcessingStatus === PRICE_UPLOAD_PROCESSING_STATUS.FAILED_VALIDATION &&
        openPriceUploadErrorNotification && (
          <Notification
            critical
            icon={<PriceUploadErrorAlertIcon style={{ position: 'absolute', top: 0, left: 0 }} />}
            message={
              <>
                <strong>
                  <Text i18nKey="notifications.priceUploadError.title">
                    Something happened with your price data upload
                  </Text>
                </strong>
                <span style={{ display: 'block' }}>
                  {priceUploadErrorMessage ? (
                    <Text>{priceUploadErrorMessage}</Text>
                  ) : (
                    <Text
                      i18nKey="notifications.priceUploadError.subtitle2"
                      variables={{
                        errorRecords: priceErrorCount,
                        totalRecords: totalUploadRecords
                      }}
                    >
                      Some records could not be processed
                    </Text>
                  )}
                </span>
              </>
            }
            action={<Text i18nKey="notifications.priceUploadError.action">Go to upload</Text>}
            link={() => handleGoToUpload(history)}
            handleClose={() => setOpenPriceUploadErrorNotification(false)}
          />
        )}
    </>
  );
};

export default PricingNotification;
